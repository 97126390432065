import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import axios from 'axios'

const ns = `newsletter-form`

const FormNewsletter = ({ tagList, successMessage, errorMessage, mode }) => {
  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  const emailInput = React.useRef(null)
  const [url, setUrl] = useState('')
  const [message, setMessage] = useState('')
  const [values, setValues] = useState({
    email: '',
    email_opt_in: true,
    tags: tagList,
  })

  useEffect(() => {
    setUrl(window.location.origin)
  }, [])

  const unFocused = () => {
    window.scrollTo(0, 0)
  }

  const addEmail = async () => {
    try {
      const request = await axios({
        url: `${url}/.netlify/functions/addEmail`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: values,
      })
      setMessage(`${successMessage}`)
      setValues({
        email: '',
        email_opt_in: true,
      })
    } catch (error) {
      setMessage(`${errorMessage}`)
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (!/\S+@\S+\.\S+/.test(values.email)) {
      setMessage('Email address is invalid')
    } else {
      addEmail()
    }
  }

  const handleChange = e => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <div className={rootClassnames}>
      <form onSubmit={handleSubmit}>
        <label htmlFor="first_name">
          <input
            name="email"
            type="email"
            id="email"
            className={mode}
            value={values.email}
            onChange={handleChange}
            ref={emailInput}
            onBlur={unFocused}
          />
        </label>
        <button className="submit-button" type="submit">
          Submit
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  )
}

export default FormNewsletter
